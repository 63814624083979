/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import WaterIcon from '../../icons/WaterIcon';
import StatBar from '../../ui/StatBar';
import usePlayer from '../../../hooks/usePlayer';
import FoodIcon from '../../icons/FoodIcon';
import TechIcon from '../../icons/TechIcon';
import StreamerCard from '../../ui/StreamerCard';
import ChevronIcon from '../../icons/ChevronIcon';
import Button from '../../ui/Button';
import { CSSTransition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from '../../../redux/config/store';
import { executeCloudScript, updateLocalVirtualCurrency } from '../../../redux/playfab';
import LoadingSpinner from '../../icons/LoadingSpinner';
import Tab from '../../ui/Tab';

const slices = [{
	currency: 'WW',
	name: 'Water',
	maxAmount: 200,
	icon: <WaterIcon />,
}, {
	currency: 'FF',
	name: 'Food',
	maxAmount: 200,
	icon: <FoodIcon />,
}, {
	currency: 'TT',
	name: 'Tech',
	maxAmount: 200,
	icon: <TechIcon />,
}];

function Slider({ maxAmount, amount, onChange }: { maxAmount: number, amount: number, onChange: (amount: number) => void }) {
	return (
		<div className="slider">
			<div className="knob" style={{ '--x': `calc((100% - var(--width)) * ${amount / maxAmount})` } as CSSProperties}></div>
			<input type="range" value={amount} min={0} max={maxAmount} onChange={(e) => { onChange(parseInt(e.target.value, 10)); }} />
			<StatBar min={0} max={maxAmount} value={amount} />
		</div>
	);
}

function Slice({ amount, name, maxAmount, icon, onChange }: { amount: number, name: string, maxAmount: number, icon: JSX.Element, onChange: (amount: number) => void }) {
	return (
		<div className="slice">
			<div className="icon">{icon}</div>
			<div className="amount">
				<div className="top">
					<span className="currency">{name}</span>
					<div className="quantity"><span className={amount > 0 ? 'active' : 'inactive'}>{amount}</span> / <span>{maxAmount}</span></div>
				</div>
				<Slider amount={amount} maxAmount={maxAmount} onChange={onChange} />
			</div>
		</div>
	);
}

export default function GiveTab({ closeTab, show }: { closeTab: () => void, show: boolean }) {
	const {
		currencies,
		DisplayName,
	} = usePlayer();
	const dispatch = useAppDispatch();

	const [isTransferring, setIsTransferring] = useState(false);
	
	const {
		playDrop,
	} = useAppSelector(state => state.env);

	const needs = {
		WW: { amount: 200, item: 'Red Bull Crate', icon: '' },
		FF: { amount: 1000, item: 'Big Mac', icon: '' },
		TT: { amount: 1500, item: <>Razer DeathAdder<br/>Mouse</>, icon: '' },
	};

	const [toSend, setToSend] = useState({
		WW: 0,
		FF: 0,
		TT: 0,
	});

	const canTransfer = Object.keys(toSend).some((currency) => toSend[currency] > 0);

	const onClickTransfer = useCallback(async() => {
		setIsTransferring(true);
		Object.entries(toSend).forEach(([currency, amount]) => {
			dispatch(updateLocalVirtualCurrency({
				currency,
				amount: currencies[currency] - amount,
			}));
		});
		setToSend({
			WW: 0,
			FF: 0,
			TT: 0,
		});
		await dispatch(executeCloudScript({ 
			functionName: 'TradeWithStreamer',
			data: {
				displayName: DisplayName,
				currencies: toSend,
				streamerPlayFabId: 'A0BEBB32933E431D',
			},
		}));
		setIsTransferring(false);
	}, [toSend, dispatch, currencies, DisplayName]);

	return (
		<Tab name="give" show={show}>
			<div className="streamer-selection">
				<div className="title">Help a streamer</div>
				<div className="streamer-select">
					<div className="streamer">
						<StreamerCard playFabId="A0BEBB32933E431D" />
					</div>
					<div className="arrow-btn"><ChevronIcon /></div>
				</div>
				<div className="streamer-needs">
					<div className="title streamer-needs">Participant Needs</div>
					<div className="needs">
						{slices.map((slice) => {
							return (
								<div className="need" key={slice.currency}>
									<div className="resource">
										<div className="amount">{needs[slice.currency].amount} <span className="icon">{slice.icon}</span></div>
									</div>
									<div className="item">
										<small>Next unlock</small>
										<br />
										{needs[slice.currency].item}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="title resources">My resources</div>
			</div>
			<div className="slices">
				{slices.map((slice) => {
					return <Slice key={slice.currency} {...slice} maxAmount={currencies[slice.currency]} amount={toSend[slice.currency]} onChange={(amount) => { setToSend(p => ({ ...p, [slice.currency]: amount })); }} />;
				})}
			</div>
			<div className="back-btn-ctn">
				<Button className="back-btn" label="Back" onClick={() => closeTab() /*setShow(false)*/} />
				<div className={`transfer-btn-ctn ${isTransferring ? 'is-transferring' : ''}`}>
					<Button className="transfer-btn" label={isTransferring ? 'Transferring...' : 'Transfer'} onClick={onClickTransfer} disabled={!canTransfer} />
					<LoadingSpinner className="spinner" />
				</div>
			</div>
		</Tab>
	);
}