import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import Home from './components/routes/Home';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import useInventoryExpiration from './hooks/useInventoryExpiration';
import useHeartbeat from './hooks/useHeartbeat';
import Debug from './components/Debug';
import Login from './components/routes/Login';
import Profile from './components/routes/Profile';
import ParallaxBG from './components/ui/ParallaxBG';
import Intro from './components/routes/Intro';
import { useAppSelector } from './redux/config/store';
import Notifications from './components/ui/Notifications';

function App() {
	/*
	* Initialize PlayFab XR connection (once logged in, we fetch all the player data, you should)
	* only use this hook once, and only at the root of your app.
	*/
	const { playerId, DisplayName, isLoaded } = usePlayFab();

	// Connect to realtime if the service is active in this app
	useRealtime();

	// Check for expired inventory items
	useInventoryExpiration();

	// Send a heartbeat to XR
	useHeartbeat();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);

	const seenIntro = useAppSelector((state) => state.env.seenIntro);

	const showLogin = seenIntro && !playerId && !isLogin;
	const showProfile = isLoaded && seenIntro && playerId && !DisplayName;

	return (
		<>
			{!seenIntro && <Navigate to={ROUTES.INTRO} replace={true} />}
			{/* If we're not logged in, and we're not on the login page, redirect to the login page */}
			{showLogin && <Navigate to={ROUTES.LOGIN} replace={true} />}
			{showProfile && <Navigate to={ROUTES.PROFILE} replace={true} />}
			<Debug />
			<Routes>
				<Route path={ROUTES.ROOT}>
					<Route index element={isLoaded && <Home />} />
					<Route path={ROUTES.INTRO} element={<Intro />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
					<Route path={ROUTES.PROFILE} element={<Profile />} />
				</Route>
			</Routes>
			<Notifications />
			<ParallaxBG play={seenIntro} />
		</>
	);
}

export default memo(App);
