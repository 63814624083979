
export const GLOBAL_NAMESPACE = 'rem-strike';

export const APP_VERSION = '2023-04-28.1';

export const STORE_NAME = 'mbc-pitch';

declare global {
	interface Window {
		APP_CONFIG: {
			BASE_PATH: string;
			AS_STACK_APP_ID: string;
			PLAYFAB_APP_ID: string;
			REALTIME_API_KEY: string;
		}
	}
}

export const XR_CONFIG = {
	apiUrl: 'https://hive-develop.xrserver.com',
	appId: window.APP_CONFIG.AS_STACK_APP_ID,
} as IXRConfig;

export const PLAYFAB_CONFIG = {
	appId: window.APP_CONFIG.PLAYFAB_APP_ID,
} as IPlayFabConfig;


export const REALTIME_CONFIG = {
	apiKey: window.APP_CONFIG.REALTIME_API_KEY,
	apiUrl: 'https://realtime-develop.xrserver.com/api',
} as IRealtimeConfig;

export const API_CONFIG = {
	xr: XR_CONFIG,
	playfab: PLAYFAB_CONFIG,
	realtime: REALTIME_CONFIG,
} as IApiConfig;

export const DEFAULT_LANG = 'en';

export const CDN_BASE = 'https://mbcsandvaultpitch.blob.core.windows.net/xr-storage/xy5hgi/assets/';

export const DEFAULT_ERROR_MESSAGE = 'An error has occurred';

export const BASE_PATH = window.APP_CONFIG.BASE_PATH;

export const ROUTES = {
	ROOT: '/',
	LOGIN: 'login',
	PROFILE: 'profile',
	INTRO: 'intro',
};

export const EXCLUDED_LOGGER_ACTIONS = [];

export const POLL_RATES = {
	SEND_HEARTBEAT: 1000 * 60,
};

export const ITEM_CLASSES = {
	OVERRIDE: 'Override',
	BADGE: 'Badge',
};

export const MISSIONS_TYPES = {};

export enum QuestionTypes {
	Multiple = 'multiple',
	MultipleImage = 'multiple-image',
	Inputs = 'inputs',
}

export const PREDICTION_MATCH_ID = 'match-id';