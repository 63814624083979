import React, { useCallback, useRef, useState } from 'react';
import { useAppDispatch } from '../../redux/config/store';
import { updateDisplayName } from '../../redux/playfab';
import usePlayer from '../../hooks/usePlayer';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../Constants';
import Button from '../ui/Button';
import { CSSTransition } from 'react-transition-group';

export default function Profile() {
	const [inputDisplayName, setInputDisplayName] = useState('');
	const { DisplayName } = usePlayer();
	const dispatch = useAppDispatch();
	const inputRef = useRef<HTMLInputElement>();

	const onSubmit = useCallback(() => {
		if (inputDisplayName.length >= 3) {
			dispatch(updateDisplayName(inputDisplayName));
		}
	}, [dispatch, inputDisplayName]);

	function onChange(e) {
		setInputDisplayName(e.target.value);
		inputRef.current.style.width = `calc(${e.target.value.length}ch + 10px)`;
	}

	return (
		<CSSTransition in={true} timeout={600} appear={true}>
			<div className="page profile">
				{DisplayName && <Navigate to={ROUTES.ROOT} replace={true} />}

				<div className="ctn">
					<p>My name is <input placeholder="" type="text" onChange={onChange} ref={inputRef} /></p>
					<Button onClick={onSubmit} disabled={inputDisplayName.length < 3} label="Continue" />
				</div>
			</div>
		</CSSTransition>
	);
}