/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDeviceOrientation } from 'react-use-device-orientation';
import { CSSTransition } from 'react-transition-group';
import { CDN_BASE } from '../../Constants';
import { constrain } from '../../utils/constrain';
import { lerp } from '../../utils/lerp';
import { map } from '../../utils/map';
import Button from './Button';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import { createPortal } from 'react-dom';
import Video from './Video';
import { setPlayFeedLoop, setPlayDrop } from '../../redux/env';

export default function ParallaxBG({ play }:{ play?: boolean }) {
	const [hasAccess, setHasAccess] = useState(false);
	const { orientation, requestAccess } = useDeviceOrientation();
	const currentGammaRef = useRef<number>(0);
	const hideLogo = useAppSelector((state) => state.env.hideLogo);
	const dispatch = useAppDispatch();
	const [videoWidth, setVideoWidth] = useState<number>(0);

	const onClick = useCallback(async() => {
		const gaveAccess = await requestAccess();

		if (gaveAccess) {
			setHasAccess(true);
		}
	}, [requestAccess]);

	useEffect(() => {
		if (!hasAccess && orientation) {
			setHasAccess(true);
		}
	}, [orientation]);

	useEffect(() => {
		requestAccess();
	}, []);

	const {
		playFeedLoop,
		playDrop,
	} = useAppSelector(state => state.env);

	const onEnded = () => {
		dispatch(setPlayFeedLoop(true));
		dispatch(setPlayDrop(false));
	};

	const videoWidthWindowPercent = (window.innerWidth / (videoWidth || 1920)) / 2 * 100;
	const minMax = 45 - videoWidthWindowPercent;
	const targetPan = constrain(map(orientation?.gamma || 0, -45, 45, -minMax, minMax), -minMax, minMax);
	const pan = lerp(targetPan, currentGammaRef.current, 0.75);
	currentGammaRef.current = pan;
	const transform = `translate(-50%, 0) translate(${pan}%, 0)`;

	const portal = createPortal(
		<CSSTransition in={play} timeout={2000} appear>
			<Button className="motion-btn" onClick={onClick} label="Grant motion access" />
		</CSSTransition>,
		document.body,
	);

	return (
		<CSSTransition in={play} timeout={2000} appear>
			<div className="parallax-bg">
				{!hasAccess && portal}
				<div className="video-container">
					<Video className="video feed-loop" style={{ transform }} onUpdateWidth={w => setVideoWidth(w)} play={playFeedLoop} src={CDN_BASE + 'day-cycle-demo.mp4'} loop />
					<Video className="video feed-drop" style={{ transform }} play={playDrop} src={CDN_BASE + 'drone_drop_night.mp4'} onEnded={onEnded} />
					{/* <video ref={videoRef} className="video" playsInline loop muted autoPlay={play} src={CDN_BASE + 'day-cycle-demo.mp4'}  /> */}
				</div>
				<img className={`logo ${!hideLogo ? 'active' : ''}`} src={CDN_BASE + 'logo-temp.png'} alt="" />
			</div>
		</CSSTransition>
	);
}