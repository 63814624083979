import React from 'react';

function MessageIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="122"
			height="107"
			viewBox="0 0 122.88 106.91"
		>
			<path fill="white" d="M56 92.15a38.3 38.3 0 0011.23 5.78c8.41 2.66 17.75 2.25 27.12-1.74a2.72 2.72 0 012-.08l12 4-1.35-9.75a2.78 2.78 0 011-2.53 28.41 28.41 0 006.31-6.8 17.53 17.53 0 002.73-12.47 27 27 0 00-6-12.5c-.6-.76-1.25-1.5-1.92-2.23a42.62 42.62 0 001.27-6.59 50 50 0 015 5.34 32.71 32.71 0 017.14 15.14A23 23 0 01119.05 84a32.7 32.7 0 01-6.29 7.12l1.61 12.4a2.79 2.79 0 01-3.6 3.21l-15.24-5a43.85 43.85 0 01-30 1.53 45 45 0 01-18.06-11.1c.65 0 1.33.06 2.06.09 2.18.06 4.34 0 6.46-.1zm16.11-56.93a6.39 6.39 0 11-6.38 6.39 6.39 6.39 0 016.38-6.39zm-42.18 0a6.39 6.39 0 11-6.38 6.39 6.39 6.39 0 016.38-6.39zm21.09 0a6.39 6.39 0 11-6.38 6.39A6.38 6.38 0 0151 35.22zM52.3 0h.05c13.94.46 26.44 5.42 35.39 13.09 9.15 7.84 14.63 18.51 14.26 30.17-.36 11.66-6.48 22-16.1 29.3-9.41 7.14-22.22 11.36-36.16 11A62.05 62.05 0 0138.5 82.2a58.64 58.64 0 01-9.43-2.87l-22.83 9 7.65-18.19a42.35 42.35 0 01-10-12.73A35.22 35.22 0 010 40.3C.37 28.63 6.49 18.28 16.11 11 25.53 3.83 38.33-.38 52.28 0zm-.17 6.35h-.05C39.62 6 28.25 9.74 19.94 16 11.83 22.2 6.66 30.83 6.37 40.47a29.15 29.15 0 003.19 14.06A36.92 36.92 0 0019.7 66.69l1.89 1.51-3.65 8.67 11.21-4.41 1.2.51a52.07 52.07 0 009.47 3 57 57 0 0010.12 1.23c12.47.36 23.85-3.36 32.16-9.66 8.11-6.16 13.28-14.79 13.57-24.43.33-9.67-4.35-18.57-12.07-25.19-7.91-6.78-19-11.16-31.45-11.54z"></path>
		</svg>
	);
}

export default MessageIcon;
