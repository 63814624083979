import React from 'react';

function GiveIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="61" height="52" viewBox='0 0 61 52' fill="none">
			<path
				fill="#fff"
				d="M60.459 25.776c-.343-1.033-1.916-1.756-4.011-1.856-.128 0-.257-.009-.385-.009-1.342 0-2.71.32-4.07.941l-11.664 5.586c-.086.042-.137.11-.145.193v.185c-.42 2.092-.761 2.47-2.455 2.898-.316.076-.641.084-.974.084H36.6l-10.629-1.176c-.308-.033-.521-.277-.496-.563a.54.54 0 01.53-.445h.086l10.612 1.143h.214c1.24 0 2.06-.706 2.146-1.832.12-1.655-1.53-4.317-5.413-4.77L20.695 23.92a4.459 4.459 0 00-.667-.05c-.7 0-1.385.167-1.984.486l-6.977 3.722a1.306 1.306 0 00-.667.806c-.103.345-.043.697.145 1l7.286 11.407c.256.403.735.655 1.24.655.247 0 .504-.059.718-.176l3.3-1.756a.563.563 0 01.266-.067h.085l11.98 1.923c.291.06.59.093.89.093.838 0 1.65-.244 2.274-.68l20.327-12.886c1.624-1.109 1.727-2.033 1.539-2.62h.008zm-51.256 4.57l-.958-1.496c-.445-.705-1.428-.94-2.198-.529l-5.25 2.823c-.599.319-.907.957-.761 1.579L4.43 51.438c.111.479.693.714 1.154.47l12.297-6.535c.376-.201.505-.638.282-.983l-8.96-14.044zM31.543 0h5.115v17.049h-5.115z"
			></path>
			<path fill="#fff" d="M42.625 5.967v5.115H25.576V5.967z"></path>
		</svg>
	);
}

export default GiveIcon;