import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_LANG, ROUTES } from '../Constants';
import { getQueryParameterByName } from '../utils/urlUtils';

export enum LoginMethods {
	CUSTOM_ID = 'custom_id',
}

export const incrementErrors = createAction('env/error');

const initialState = {
	Lang: getQueryParameterByName('lang') || DEFAULT_LANG,
	LastPath: ROUTES.ROOT,
	Errors: [],
	isLoggingIn: false,
	loggingMethod: null as LoginMethods,
	background: null as string,
	seenIntro: Boolean(localStorage.getItem('seenIntro')),
	hideLogo: false,
	playFeedLoop: true,
	playDrop: false,
};

const env = createSlice({
	name: 'env',
	reducers: {
		setLastPath: (state, action:PayloadAction<string>) => { state.LastPath = action.payload; },
		setIsLoggingIn: (state, action:PayloadAction<boolean>) => {
			state.isLoggingIn = action.payload;
		},
		setLoggingMethod: (state, action:PayloadAction<LoginMethods>) => { state.loggingMethod = action.payload; },
		setBackground: (state, action:PayloadAction<string>) => { state.background = action.payload; },
		setSeenIntro: (state, action:PayloadAction<boolean>) => {
			state.seenIntro = action.payload;
			localStorage.setItem('seenIntro', 'true');
		},
		setHideLogo: (state, action:PayloadAction<boolean>) => { state.hideLogo = action.payload; },
		setPlayFeedLoop: (state, action:PayloadAction<boolean>) => {
			state.playFeedLoop = action.payload;
		},
		setPlayDrop: (state, action:PayloadAction<boolean>) => {
			state.playDrop = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(incrementErrors, (state) => {
			state.Errors = [
				...state.Errors,
				Date.now(),
			];
		});
	},
	initialState,
});

export default env;

export const { setLastPath, setIsLoggingIn, setLoggingMethod, setBackground, setSeenIntro, setHideLogo, setPlayFeedLoop, setPlayDrop } = env.actions;
