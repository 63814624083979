import React from 'react';
import usePlayer from '../../hooks/usePlayer';
import LoadingSpinner from '../icons/LoadingSpinner';
import { CDN_BASE } from '../../Constants';
import { useOtherPlayerProfile } from '../../hooks/useOtherPlayer';

export default function Avatar({ playerId }:{ playerId?: string }) {
	const { AvatarUrl = CDN_BASE + 'defaultAvatar.jpg' } = usePlayer();
	const otherPlayer = useOtherPlayerProfile(playerId);

	const toShow = otherPlayer?.AvatarUrl || AvatarUrl;
	const showLoading = playerId && !otherPlayer || !AvatarUrl;

	return (
		<div className="avatar">
			<div className="image-ctn">
				{!showLoading && <img src={toShow} alt="" />}
				{showLoading && <LoadingSpinner className="spinner" />}
			</div>
		</div>
	);
}