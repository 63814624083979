import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Avatar from '../ui/Avatar';
import usePlayer from '../../hooks/usePlayer';
import useStats from '../../hooks/useStats';
import GiveIcon from '../icons/GiveIcon';
import StatsIcon from '../icons/StatsIcon';
import GiveTab from './home/GiveTab';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import { setHideLogo } from '../../redux/env';
import { CSSTransition } from 'react-transition-group';
import MessageIcon from '../icons/MessageIcon';
import { writePlayerEvent } from '../../redux/playfab';
import LoadingSpinner from '../icons/LoadingSpinner';
import StatsTab from './home/StatsTab';
import useAnimationFrame from '../../hooks/useAnimationFrame';

const TABS = {
	stats: 'stats',
	give: 'give',
} as const;
type TAB = typeof TABS[keyof typeof TABS];

function SMSButton() {
	const dispatch = useAppDispatch();
	const [isDisabled, setIsDisabled] = useState(false);

	function fakeSendSms() {
		setIsDisabled(true);
		dispatch(writePlayerEvent({
			name: 'player_send_sms',
		}));
		setTimeout(() => {
			setIsDisabled(false);
		}, 2000);
	}

	return (
		<div className={`tab-btn sms ${isDisabled ? 'disabled' : ''}`} onClick={fakeSendSms}>
			<MessageIcon />
			<LoadingSpinner className="spinner" />
		</div>
	);
}

function Home() {
	const { level } = useStats();
	const { DisplayName } = usePlayer();
	const dispatch = useAppDispatch();
	const { playDrop } = useAppSelector((state) => state.env);

	const [currentTab, setCurrentTab] = useState<TAB>(null);

	const changeTab = useCallback((tab: TAB) => {
		let t = tab;
		if (t === currentTab) {
			t = null;
		}

		setCurrentTab(t);
		dispatch(setHideLogo(t !== null));
	}, [currentTab]);

	useEffect(() => {
		return () => {
			dispatch(setHideLogo(false));
		};
	}, []);
	
	const tabRef = useRef<HTMLDivElement>(null);
	const lastHeight = useRef<number>(0);

	useAnimationFrame(() => {
		const sh = tabRef.current.querySelector('.tab.active')?.scrollHeight || 0;

		if (tabRef.current && lastHeight.current !== sh) {
			tabRef.current.style.setProperty('--height', `${sh}px`);
			lastHeight.current = sh;
		}
	});

	useEffect(() => {
		if (playDrop) {
			changeTab(null);
		}
	}, [playDrop]);

	return (
		<CSSTransition in={true} timeout={1000} appear>
			<div className="page home">
				<div className="tabs">
					<div className="profile-button">
						<Avatar />
						<div className="infos">
							<div className="level">LVL {level}</div>
							<div className="display-name">{DisplayName}</div>
						</div>
					</div>
					<div className="tab-btns">
						<SMSButton />
						<div className={`tab-btn ${TABS.stats} ${currentTab === TABS.stats ? 'active' : ''}`} onClick={() => { changeTab(TABS.stats); }}><StatsIcon /></div>
						<div className={`tab-btn ${TABS.give} ${currentTab === TABS.give ? 'active' : ''}`} onClick={() => { changeTab(TABS.give); }}><GiveIcon /></div>
					</div>
					<div className="tab-content" ref={tabRef}>
						<StatsTab closeTab={() => changeTab(null)} show={currentTab === TABS.stats} />
						<GiveTab closeTab={() => changeTab(null)} show={currentTab === TABS.give} />
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(Home);
