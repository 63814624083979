import React from 'react';

function FoodIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="47" viewBox='0 0 32 47' fill="none">
			<g fill="#fff" clipPath="url(#a)">
				<path d="M8.7 12.803c-.6 0-1.085-.484-1.085-1.082V1.081C7.615.483 8.099 0 8.7 0c.6 0 1.084.483 1.084 1.081v10.647c0 .599-.484 1.082-1.084 1.082v-.008zm-3.464 0c-.6 0-1.085-.484-1.085-1.082V1.081C4.151.483 4.636 0 5.236 0S6.32.483 6.32 1.081v10.647c0 .599-.484 1.082-1.084 1.082v-.008z"></path>
				<path d="M6.964 47c-2.162 0-3.912-1.745-3.912-3.893v-.158l.875-22.304L.455 17.41l-.028-.03A1.457 1.457 0 010 16.27L.68 1.032A1.083 1.083 0 011.815 0a1.08 1.08 0 011.034 1.124l-.665 14.93L5.77 19.39c.231.216.354.519.34.829L5.22 43v.093c0 .959.788 1.737 1.75 1.737a1.73 1.73 0 001.258-.533c.333-.346.507-.8.492-1.283L7.83 20.22c-.014-.31.116-.613.34-.829l3.588-3.345-.673-14.922A1.072 1.072 0 0112.12 0a1.077 1.077 0 011.128 1.03l.68 15.254c.022.396-.137.8-.434 1.096-.007.007-.014.014-.029.021l-3.464 3.237.875 22.303a3.897 3.897 0 01-1.099 2.862 3.871 3.871 0 01-2.813 1.19V47zm17.125 0a3.916 3.916 0 01-2.82-1.19 3.897 3.897 0 01-1.1-2.861l.868-22.224c-3.305-2.523-5.106-6.582-4.845-10.972.238-4.051 2.19-7.526 5.098-9.061a5.961 5.961 0 015.576 0c2.907 1.535 4.86 5.01 5.098 9.061.26 4.39-1.54 8.442-4.845 10.965l.867 22.216a3.869 3.869 0 01-1.099 2.862 3.871 3.871 0 01-2.813 1.19l.015.014zm0-44.837a3.75 3.75 0 00-1.772.44c-2.206 1.167-3.76 4.022-3.949 7.28-.224 3.842 1.418 7.36 4.405 9.422.303.209.477.562.462.93l-.89 22.793a1.758 1.758 0 001.751 1.817 1.72 1.72 0 001.258-.534c.333-.346.506-.8.492-1.283l-.89-22.786a1.08 1.08 0 01.463-.93c2.987-2.055 4.628-5.58 4.404-9.422-.195-3.258-1.742-6.113-3.948-7.28a3.777 3.777 0 00-1.78-.44l-.006-.007z"></path>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h32v47H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default FoodIcon;
