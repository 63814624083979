import React from 'react';

function StatsIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="31" height="42" viewBox='0 0 31 42' fill="none">
			<path
				fill="#fff"
				d="M0 0h6.739v41.783H0zm12.13 21.565h6.739v20.217H12.13zm12.131-6.739H31v26.956h-6.739z"
			></path>
		</svg>
	);
}

export default StatsIcon;
