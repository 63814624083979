import React from 'react';
import Avatar from './Avatar';
import { useOtherPlayerProfile } from '../../hooks/useOtherPlayer';

const trimNumber = (str:string) => {
	return str?.length ? str.slice(0, str.length - 4) : '';
};

export default function StreamerCard({ playFabId }: { playFabId: string }) {
	const otherPlayer = useOtherPlayerProfile(playFabId);

	const {
		DisplayName,
		Statistics,
	} = otherPlayer || {};

	const ctn = Statistics?.find(stat => stat.Name === 'container_id')?.Value || 0;

	return (
		<div className="streamer-card">
			<Avatar playerId={playFabId} />
			<div className="infos">
				<div className="ctn">Container #{ctn}</div>
				<div className="name">{trimNumber(DisplayName)}</div>
			</div>
		</div>
	);
}