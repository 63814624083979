import React from 'react';

function WaterIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="48" height="50" viewBox='0 0 48 50' fill="none">
			<path
				fill="#fff"
				d="M15.423.45c-.49-.6-1.41-.6-1.9 0C9.622 5.23.332 17.767.332 24.167c0 7.799 6.344 14.143 14.143 14.143 7.798 0 14.142-6.345 14.142-14.143 0-6.405-9.334-18.99-13.193-23.717zm-.95 35.407c-6.446 0-11.69-5.244-11.69-11.69 0-5.048 7.716-15.915 11.69-20.977 3.975 5.062 11.69 15.93 11.69 20.977 0 6.446-5.244 11.69-11.69 11.69z"
			></path>
			<path
				fill="#fff"
				d="M12.777 11.723a1.226 1.226 0 00-1.7.34c-2.536 3.802-4.285 6.98-5.2 9.447a1.227 1.227 0 002.3.854c.583-1.573 1.914-4.401 4.94-8.94a1.226 1.226 0 00-.34-1.701zm-.58 18.835a6.813 6.813 0 01-3.635-3.066A1.226 1.226 0 106.426 28.7a9.268 9.268 0 004.945 4.17 1.226 1.226 0 00.826-2.31zm26.774-5.94c-.49-.6-1.41-.6-1.9 0-.89 1.09-8.698 10.788-8.698 15.734 0 5.32 4.328 9.648 9.648 9.648 5.32 0 9.648-4.328 9.648-9.648 0-4.946-7.807-14.645-8.698-15.734zm-.95 22.929c-3.967 0-7.195-3.228-7.195-7.195 0-3.264 5.222-10.413 7.195-12.976 1.985 2.577 7.195 9.716 7.195 12.976 0 3.967-3.227 7.195-7.195 7.195z"
			></path>
			<path
				fill="#fff"
				d="M36.593 34.315a1.227 1.227 0 00-1.685.416c-1.566 2.591-2.139 4.126-2.344 4.958a1.227 1.227 0 002.382.587c.108-.439.526-1.735 2.062-4.277.35-.58.164-1.333-.415-1.684z"
			></path>
		</svg>
	);
}

export default WaterIcon;