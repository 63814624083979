import React, { useCallback, useEffect, useRef } from 'react';
import { CDN_BASE, ROUTES } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/config/store';
import { setSeenIntro } from '../../redux/env';

export default function Intro() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const onEnded = useCallback(() => {
		dispatch(setSeenIntro(true));
		navigate(ROUTES.ROOT);
	}, [navigate]);

	const video = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (video.current) {
			video.current.playbackRate = 1;
		}
	}, [video]);

	return (
		<div className="page intro">
			<div className="video-container">
				<video ref={video} className='video' src={CDN_BASE + 'intro-app.mp4'} autoPlay playsInline muted onEnded={onEnded}></video>
			</div>
		</div>
	);
}