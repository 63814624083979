/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, {  } from 'react';
import Tab from '../../ui/Tab';
import WaterIcon from '../../icons/WaterIcon';
import FoodIcon from '../../icons/FoodIcon';
import TechIcon from '../../icons/TechIcon';
import useStats from '../../../hooks/useStats';
import StatBar from '../../ui/StatBar';

export default function StatsTab({ closeTab, show }: { closeTab: () => void, show: boolean }) {
	const {
		trade_ww,
		trade_ff,
		trade_tt,
		trades_sent,
	} = useStats();

	const slices = [
		{ name: 'Water', stat: 'trade_ww', value: trade_ww, icon: <WaterIcon /> },
		{ name: 'Food', stat: 'trade_ff', value: trade_ff, icon: <FoodIcon /> },
		{ name: 'Tech', stat: 'trade_tt', value: trade_tt, icon: <TechIcon /> },
	];

	const maxAmount = [trade_ww, trade_ff, trade_tt].reduce((a, b) => a + b, 0);

	return (
		<Tab name="stats" show={show}>
			<div className="title">Resources given</div>
			<div className="materials-given">
				{slices.map(({ icon, name, value }) => {
					return (
						<div className="slice" key={name}>
							<div className="icon">{icon}</div>
							<div className="amount">
								<div className="top">
									<span className="currency">{name}</span>
									<div className="quantity"><span className={value > 0 ? 'active' : 'inactive'}>{value}</span> / <span>{(value / maxAmount * 100).toFixed(0)}%</span></div>
								</div>
								<StatBar min={0} max={maxAmount} value={value} />
							</div>
						</div>
					);
				})}
			</div>
		</Tab>
	);
}