import React from 'react';
import { constrain } from '../../utils/constrain';
import { map } from '../../utils/map';

type StatBarProps = {
	value: number,
	min: number,
	max: number,
	className?: string,
};

export default function StatBar({ value, min, max, className = '' }: StatBarProps) {
	let width = constrain(map(value, min, max, 0, 100), 0, 100);

	if (value === min && value === max) {
		width = 0;
	}
	
	return (
		<>
			<div className={`stat-bar ${className}`}>
				<div className="stat-bar-current-level">{min}</div>
				<div className="stat-bar-inner" style={{ width: `${width}%` }} />
				<div className="stat-bar-next-level">{max}</div>
			</div>
		</>
	);
}