import React from 'react';

function TechIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox='0 0 47 47' fill="none">
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M12.73 4.904V0h1.962v4.904h7.826V0h1.962v4.904h7.826V0h1.962v4.904h2.411a5.435 5.435 0 015.436 5.436v2.39h4.883v1.962h-4.883v7.835l4.882-.009L47 24.48l-4.885.008v7.818h4.883v1.962h-4.883v2.411a5.436 5.436 0 01-5.436 5.436h-2.411v4.883h-1.962v-4.883H24.49L24.48 47l-1.962-.003.009-4.882h-7.835v4.883H12.73v-4.883h-2.39a5.435 5.435 0 01-5.436-5.436v-2.411H0v-1.962h4.904V24.48H0v-1.962h4.904v-7.826H0V12.73h4.904v-2.39a5.435 5.435 0 015.436-5.436h2.39zm-2.39 1.962a3.474 3.474 0 00-3.474 3.474v26.34a3.474 3.474 0 003.474 3.473h26.34a3.474 3.474 0 003.473-3.474V10.34a3.474 3.474 0 00-3.474-3.473H10.34zm7.295 7.826a2.942 2.942 0 00-2.943 2.943v11.729a2.942 2.942 0 002.943 2.942h11.729a2.942 2.942 0 002.942-2.942v-11.73a2.942 2.942 0 00-2.942-2.942h-11.73zm-4.905 2.943a4.904 4.904 0 014.905-4.905h11.729a4.904 4.904 0 014.904 4.905v11.729a4.904 4.904 0 01-4.904 4.904h-11.73a4.904 4.904 0 01-4.904-4.904v-11.73z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

export default TechIcon;