import React, { useRef, useLayoutEffect, useEffect, memo, CSSProperties, useState, useCallback } from 'react';

type VideoProps = {
	src: string,
	play:boolean,
	loop?: boolean,
	muted?: boolean,
	onEnded?: () => void,
	onLoad?: () => void,
	onUpdateWidth?: (width:number) => void,
	className?:string,
	style?:CSSProperties,
	onVideoPlaybackError?: () => void
};

function Video({ src, muted = true, play, onLoad = () => {}, onEnded = () => {}, className = '', onVideoPlaybackError, loop = false, style = null, onUpdateWidth = () => {} }:VideoProps) {
	const ref = useRef<HTMLVideoElement>(null);
	const [videoWidth, setVideoWidth] = useState<number>(0);

	const onVideoLoad = useCallback(() => {
		setVideoWidth(ref.current?.getBoundingClientRect().width || 0);
		onLoad?.();
	}, [onLoad]);

	useLayoutEffect(() => {
		ref.current.currentTime = 0;

		if (play && ref.current.paused) {
			ref.current.play().catch((e) => {
				onVideoPlaybackError?.();
			});
		} else if (!play && !ref.current.paused) {
			ref.current.pause();
		}
	}, [play, src]);

	useEffect(() => {
		if (ref.current) {
			ref.current.currentTime = 0;
			// ref.current.playbackRate = 0.5;
		}

		const onResize = () => {
			setVideoWidth(ref.current?.getBoundingClientRect().width || 0);
		};

		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	useEffect(() => {
		onUpdateWidth?.(videoWidth);
	}, [videoWidth]);

	return (
		<video className={`${className} ${play ? 'playing' : ''}`} style={style} ref={ref} src={src} preload='preload' loop={loop} muted={muted} playsInline onEnded={onEnded} onLoad={onVideoLoad} />
	);
}

export default memo(Video);