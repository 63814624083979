/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';

export default function Tab({ name, show, children }: PropsWithChildren<{ name:string, show: boolean }>) {
	return (
		<CSSTransition in={show} timeout={600} appear>
			<div className={`tab ${name} ${show ? 'active' : ''}`}>
				<div className="content">
					{children}
				</div>
			</div>
		</CSSTransition>
	);
}